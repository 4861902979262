var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('table',{staticClass:"table_form line-bin fl",staticStyle:{"width":"100%"}},[_c('thead',{staticStyle:{"border-top":"1px solid","border-bottom":"1px solid","border-color":"rgba(229, 231, 235)"}},[_c('tr',[_c('th',[_vm._v("JOBID")]),_c('td',[_vm._v(_vm._s(_vm.rowInfo.id))]),_c('th',[_vm._v("DISPLAY")]),_c('td',[_vm._v(_vm._s(_vm.rowInfo.display))]),_c('th',[_vm._v("등록일")]),_c('td',[_vm._v(_vm._s(_vm.formatDt(_vm.rowInfo)))]),_c('th',[_vm._v("결과")]),_c('td',[_vm._v(_vm._s(_vm.rowInfo.result))])])])]),_c('table',{staticClass:"table_form line-bin",staticStyle:{"top":"30px"}},[_c('tbody',[_c('esp-dx-data-grid',{ref:"workDetailGrid",attrs:{"data-grid":_vm.dataGrid,"auto-width":true}})],1)]),_c('DxPopup',{attrs:{"show-title":true,"title":_vm.modal.title,"width":_vm.modal.width,"height":_vm.modal.height,"drag-enabled":true,"resize-enabled":true,"show-close-button":true,"close-on-outside-click":false,"visible":_vm.modal.isOpened},on:{"hiding":function($event){return _vm.isOpenModal(false)}}},[[_c('DxTextArea',{attrs:{"styling-mode":_vm.stylingMode,"height":550},model:{value:(_vm.formatJson),callback:function ($$v) {_vm.formatJson=$$v},expression:"formatJson"}}),_c('DxToolbarItem',{attrs:{"widget":"dxButton","toolbar":"bottom","location":"center","visible":true,"options":{
            elementAttr: {
              class: 'default filled txt_S medium',
            },
            text: '확인',
            //type: 'default',
            width: '120',
            height: '40',
            useSubmitBehavior: true,
            onClick: () => {
              _vm.isOpenModal(false);
            },
          }}})]],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }